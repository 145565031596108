import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// api
import { resetPassword } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  password: "",
  confirmPassword: "",
  passwordIcon: false,
  confirmPasswordIcon: false,
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { authToken } = useParams();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const { password, passwordIcon, confirmPasswordIcon, confirmPassword } =
    formValue;

  //function
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handlePassIcon = (name) =>
    setFormValue({ ...formValue, ...{ [name]: !formValue[name] } });

  const handleSubmit = async (e) => {
    try {
      const { status, message, errors } = await resetPassword({
        authToken,
        password,
        confirmPassword,
      });
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", message, "signup", "TOP_CENTER");
        navigate("/login");
      } else {
        if (errors) {
          setErrors(errors);
          return false;
        }
        toastAlert("error", message, "signup", "TOP_CENTER");
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 m-auto">
          <div className="dash_box mb-0">
            <div className="inner_title_wrapper mt-2">
              <h2 className="inner_title">Reset Password</h2>
            </div>
            <form className="auth_form">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  New Password
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!passwordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    id="exampleFormControlInput1"
                    placeholder="Enter New Password"
                    name="password"
                    onChange={handleChange}
                    value={password}
                  />
                  <span className="withdrawal_type">
                    <i
                      class={
                        passwordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"
                      }
                      onClick={() => handlePassIcon("passwordIcon")}
                    ></i>
                  </span>
                </div>
                <span className="text-danger">{errors?.password}</span>
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Confirm Password
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!confirmPasswordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    id="exampleFormControlInput1"
                    placeholder="Enter Confirm Password"
                    name="confirmPassword"
                    onChange={handleChange}
                    value={confirmPassword}
                  />
                  <span className="withdrawal_type">
                    <i
                      class={
                        confirmPasswordIcon
                          ? "bi bi-eye-fill"
                          : "bi bi-eye-slash-fill"
                      }
                      onClick={() => handlePassIcon("confirmPasswordIcon")}
                    ></i>
                  </span>
                </div>
                <span className="text-danger">{errors?.confirmPassword}</span>
              </div>
              <div className="text-center mt-4 mb-4">
                <button
                  type="button"
                  class="primary_btn "
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
