import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import copy_icon from "../../assets/images/copy_icon.svg";

// import lib
import { textCopy, toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import { toFixedDown } from "../../lib/roundOf";

//import api
import { DepositRequest } from "../../api/walletAction";

//import validation
import { DepositValidation } from "./validation";

const initialFormValue = {
  amount: 0,
  image: "",
  memoAddress: "",
};

let imageType = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "pdf", "PDF"];

const Deposit = (props) => {
  const { coin } = props;

  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);

  const history = useNavigate();

  //state
  const [formValue, setFormValue] = useState(initialFormValue);
  const { amount, image, memoAddress } = formValue;
  const [userAsset, setUserAssetData] = useState([]);
  const [selectAsset, setSelectAsset] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  //function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "amount") {
      if (/[^0-9.]/.test(value)) return;
    }
    let formData = { ...formValue, ...{ [name]: toFixedDown(value, 8) } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
    if (value) {
      setLoader(false);
    }
  };

  const handleAddrss = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
    if (value) {
      setLoader(false);
    }
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
    if (!imageType.includes(e.target.files[0].type.split("/")[1])) {
      return toastAlert("error", "Invalid image", "deposit");
    }
    if (!isEmpty(errors)) {
      setErrors({});
    }
    if (files) {
      setLoader(false);
    }
  };

  const handleAsset = () => {
    let tempArr = [...walletData];
    currency?.length > 0 &&
      currency.map((item, index) => {
        let pairIndex =
          tempArr &&
          tempArr.findIndex((el) => {
            return el._id == item._id;
          });
        if (pairIndex >= 0 && !isEmpty(pairIndex)) {
          tempArr[pairIndex] = {
            ...tempArr[pairIndex],
            ...{
              image: item.image,
              minDeposit: item.minimumDeposit,
              adminAddress: item.adminAddress,
              status: item.status,
            },
          };
        }
      });
    setUserAssetData(tempArr);
    let checkIndex =
      tempArr &&
      tempArr.findIndex((el) => {
        return el._id == coin;
      });
    if (checkIndex >= 0) {
      setSelectAsset(tempArr[checkIndex]);
    } else {
      let newArr = tempArr.filter((item) => {
        return item.status == "active";
      });
      setSelectAsset(newArr[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      if (isEmpty(selectAsset?.adminAddress)) {
        toastAlert(
          "error",
          "Maintenance in process try after some time!",
          "DepositRequest"
        );
        return;
      }

      let reqData = {
        userAssetId: coin,
        minimumDeposit: selectAsset?.minDeposit,
        amount,
        image,
      };
      let validationError = DepositValidation(reqData);
      if (!isEmpty(validationError)) {
        setErrors(validationError);
        setLoader(false);
        return;
      }

      const formData = new FormData();
      formData.append("userAssetId", coin);
      formData.append("amount", amount);
      formData.append("image", image);
      formData.append("memoAddress", memoAddress);
      setLoader(true);
      const { status, loading, message, errors } = await DepositRequest(
        formData
      );
      if (status === "success") {
        setLoader(false);
        toastAlert("success", message, "DepositRequest");
        setErrors({});
        setFormValue(initialFormValue);
        history("/wallet");
      } else {
        if (message) return toastAlert("error", message, "DepositRequest");
        setErrors(errors);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "error");
    }
  };

  

  useEffect(() => {
    if (!isEmpty(walletData) && !isEmpty(currency)) handleAsset();
  }, [walletData, currency]);


  // console.log(selectAsset, 'selectAssetselectAsset')

  return (
    <div className="dash_wrapper">
      <div className="row">
        <div className="col-lg-12">
          <div className="dash_box asset_dashbox_flex asset_dashbox_flex_line">
            <div className="asset_dashbox_flex_left">
              <h3 className="asset_deposit_innertitle">Deposit Terms</h3>
              <div className="d-none d-lg-block">
                <div
                  role="alert"
                  className="primary_warning_alert alert alert-secondary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <path
                      d="M13 26.7017C5.81491 26.7017 0 20.879 0 13.6829C0 6.48749 5.81425 0.664062 13 0.664062C20.1851 0.664062 26 6.48673 26 13.6829C26 20.8783 20.1857 26.7017 13 26.7017ZM14.335 8.51497C14.335 8.10365 13.7361 7.69244 13.0003 7.69244C12.2302 7.69244 11.6827 8.10365 11.6827 8.51497V15.0441C11.6827 15.5239 12.2303 15.8495 13.0003 15.8495C13.7361 15.8495 14.335 15.5239 14.335 15.0441V8.51497ZM13.0003 17.426C12.2131 17.426 11.5972 18.0086 11.5972 18.6599C11.5972 19.3111 12.2132 19.8766 13.0003 19.8766C13.7704 19.8766 14.3864 19.3111 14.3864 18.6599C14.3864 18.0086 13.7703 17.426 13.0003 17.426Z"
                      fill="#36a2a8"
                    />
                  </svg>
                  <div className="alertCard">
                    <h3>
                      Minimum deposit amount: {selectAsset?.minDeposit}{" "}
                      {selectAsset?.coin}
                    </h3>
                    <p>
                      Deposits less than the minimum amount will not be accepted
                      and refunds are not allowed
                    </p>
                  </div>
                </div>
              </div>
              <ul className="deposit_notes_list">
                <li>Send Only {selectAsset?.coin} To This Wallet Address.</li>
                <li>
                  Sending Coins Or Tokens Other Than {selectAsset?.coin} To This
                  Wallet May Result In The Total Loss Of Your Deposit
                </li>
                <li>
                  Please upload a screenshot or a hash of your transaction in
                  order for us to approve your transaction
                </li>
              </ul>
              {/* <div className="text-center mt-4 mb-4">
                <a href={siteDoc?.externalLink} target="_blank" > <button type="button" className="primary_btn ">
                  Deposit with Card
                </button>
                </a>
              </div> */}
            </div>
            <div className="asset_dashbox_flex_right">
              <h3 className="asset_deposit_innertitle">
                {selectAsset?.coin} Deposit
              </h3>
              <div className="text-center my-3">
              <QRCode
                  value={!isEmpty(selectAsset.address) ? selectAsset.address : selectAsset.adminAddress}
                />
              </div>
              <form className="assets_form">
                <div className="mb-3">
                  <label for="depositaddress" className="form-label">
                    Deposit Address
                  </label>
                  <div className="asset_deposit_grp">
                    <input
                      type="text"
                      className="form-control primary_asset_inp"
                      id="depositaddress"
                      value={!isEmpty(selectAsset.address) ? selectAsset.address : selectAsset.adminAddress}
                      disabled
                    />
                    <img
                      src={copy_icon}
                      alt="Copy"
                      className="asset_copy_icon"
                      onClick={() => textCopy(!isEmpty(selectAsset.address) ? selectAsset.address : selectAsset.adminAddress)}
                    />
                  </div>
                </div>
                {selectAsset?.coin === "XRP" && (
                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Memo Address
                    </label>
                    <input
                      type="text"
                      name="memoAddress"
                      value={memoAddress}
                      onChange={handleAddrss}
                      className="form-control primary_asset_inp"
                    />
                    {/* <span className='text-danger'>{errors?.memoAddress}</span> */}
                  </div>
                )}
                <div className="mb-3">
                  <label for="email" className="form-label">
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    value={amount}
                    onChange={handleChange}
                    className="form-control primary_asset_inp"
                  />
                  <span className="text-danger">{errors?.amount}</span>
                </div>
                <div className="mb-3">
                  <label for="withdrawamount" className="form-label">
                    Upload Document (Optional)
                  </label>
                  <div className="custom_file_upload btn">
                    Upload
                    <input type="file" name="image" onChange={handleFile} />
                  </div>
                  <div className="form-text ms-1">
                    Max. upload file size 2MB (Jpg, Jpeg, Png, Pdf only).
                  </div>
                  <span className="text-danger">{errors?.image}</span>
                </div>
                {image?.name && (
                  <div className="uploaded_files_row">
                    <div>
                      <i className="bi bi-file-earmark-plus"></i>
                      <span>{image?.name}</span>
                    </div>
                    <div>
                      <i
                        className="bi bi-x-circle"
                        onClick={() =>
                          setFormValue({ ...formValue, ...{ ["image"]: "" } })
                        }
                      ></i>
                    </div>
                  </div>
                )}
                {/* <div className="deposit_asset_details">
                  <div>
                    <label>chain network</label>
                   <label>{currencyDoc?.tokenType?.toUpperCase()}</label> 
                  </div>
                </div> */}
                <div className="text-center mt-4 mb-4">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={loader}
                    className="primary_btn "
                  >
                    Deposit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
