import React, { useEffect, useState } from "react";
import logo_dark from "../assets/images/logo-dark.png";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "./ThemeContext";

export default function Footer() {
  //redux
  const { isAuth } = useSelector((state) => state.auth);
  const siteDoc = useSelector((state) => state.siteSetting)

  const { theme } = useTheme();





  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel_top">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div className="left-footer-links">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>Instruments</h3>
                      <a href="/cms/instruments-crypto">Crypto</a>
                      <a href="/cms/instruments-forex">Forex</a>
                      <a href="/cms/instruments-stocks">Stocks</a>
                      <a href="/cms/instruments-indices">Indices</a>
                      <a href="/cms/instruments-commodities">Commodities</a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>Trading</h3>
                      <Link to="/">Platform</Link>
                      {siteDoc?.walletAccess &&  <Link to={isAuth == true ? "/wallet" : "/login"}>Deposit </Link> }
                      <Link to={isAuth == true ? "/" : "/register"}>Open Account</Link>
                      <Link to={isAuth == true ? "/" : "/login"}>Sign In </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>Instruments</h3>
                      <a href="/cms/platform-mt5">Mt5 Platform </a>
                      <a href="/cms/platform-webtrader">Webtrader Platform </a>
                      <a href="/cms/platform-mobile">Mobile Platform </a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>FX Payments</h3>
                      <Link to="/account-types">Account Types</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="footer-about-card">
                <p className="footer_abt_content">Currency goes by </p>
                <Link className="navbar-brand" to="/">
                  {theme === "light" ? (
                    <img
                      src={logo}
                      className="img-fluid"
                      alt="Light logo"
                    />
                  ) : (
                    <img
                      src={logo_dark}
                      className="img-fluid"
                      alt="Dark logo"
                    />
                  )}
                </Link>
                <p className="footer_abt_content">Elevate your crypto staking experience with Stake500 ---
                  Amplify Security, Maximize Growth, and Stake with
                  Confidence!</p>
                <div>
                  <h5>Contact Us</h5>
                  <span>Address: {siteDoc?.address} </span><br />
                  <span>Phone Number : {siteDoc?.contactNo}</span>
                  <br />
                  <span>Email : {siteDoc?.supportMail}</span>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="footer_panel_bottom">
          <p>
            Copyright © {new Date().getFullYear()} Stake500, All right reserved
          </p>
          <ul className="footer_menu_links">
            <li>
              <Link to="/terms">Terms of service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
