import React, { useEffect, useState, useContext } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";



// import components
import Trade from "../components/Trade";



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function TradePage(props) {



  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container-fluid">
          <Trade />
        </div>
      </div>
      <Footer />
    </div>
  );
}
