import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//import api
import { getAssetData } from "../../api/walletAction";

//import component
import WalletDetails from "./WalletDetails";

const Wallet = () => {
  const dispatch = useDispatch();
  const siteDoc = useSelector((state) => state.siteSetting)



  useEffect(() => {
    getAssetData(dispatch);
  }, []);



  return (
    <>
      <div className="innerpages_wrapper">
        <div className="container">
          {/* <div className="inner_title_wrapper">
            <h2 className="inner_title">Wallet</h2>
          </div> */}
          <div className="dash_wrapper">
            <div className="d-flex gap-2 align-items-center justify-content-end mt-4 mb-4">
              <a
                href={siteDoc?.whatsappLink}
                className="whatsapp_btn"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <svg
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "5px",
                  }}
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <path
                    d="M0.034668 32L2.27334 24.049C0.833743 21.6033 0.0757846 18.8207 0.0757846 15.9621C0.0757846 7.16059 7.23637 0 16.0379 0C24.8395 0 32 7.16059 32 15.9621C32 24.7637 24.8395 31.9243 16.0379 31.9243C13.2954 31.9243 10.61 31.2223 8.23431 29.8893L0.034668 32ZM8.6535 26.9853L9.1421 27.2837C11.2134 28.5482 13.598 29.2166 16.0379 29.2166C23.3464 29.2166 29.2923 23.2706 29.2923 15.9621C29.2923 8.65364 23.3464 2.70771 16.0379 2.70771C8.72942 2.70771 2.78349 8.65364 2.78349 15.9621C2.78349 18.5087 3.50725 20.9816 4.87635 23.1136L5.20539 23.626L3.91622 28.2048L8.6535 26.9853Z"
                    fill="#fff"
                  ></path>{" "}
                  <path
                    d="M11.554 8.53295L10.5182 8.47649C10.1929 8.45874 9.87378 8.56744 9.62788 8.78105C9.12575 9.21709 8.32286 10.0601 8.07626 11.1586C7.70852 12.7966 8.27683 14.8023 9.74772 16.808C11.2186 18.8137 13.9597 22.0229 18.8068 23.3935C20.3688 23.8351 21.5975 23.5374 22.5455 22.9309C23.2963 22.4507 23.8139 21.6798 24.0004 20.8083L24.1658 20.0359C24.2183 19.7904 24.0937 19.5413 23.8656 19.4362L20.3651 17.8227C20.1379 17.718 19.8684 17.7842 19.7156 17.9822L18.3414 19.7637C18.2376 19.8983 18.0599 19.9517 17.8994 19.8953C16.9583 19.5646 13.806 18.2443 12.0763 14.9124C12.0012 14.7679 12.0199 14.5925 12.1263 14.4692L13.4396 12.9499C13.5738 12.7948 13.6077 12.5767 13.5271 12.3881L12.0182 8.85797C11.9379 8.67004 11.7578 8.54408 11.554 8.53295Z"
                    fill="#fff"
                  ></path>{" "}
                </svg>
                Start a chat
              </a>
              <a href={siteDoc?.externalLink} target="_blank">
                {" "}
                <button type="button" className="primary_btn">
                  Deposit with Card
                </button>
              </a>
            </div>
            <WalletDetails />
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
