import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { useSelector } from "react-redux";

//import api
import { getStaking } from "../../api/staking";

//import lib
import isEmpty from "../../lib/isEmpty";
import { interestByDays } from "../../lib/calculation";
import { toFixedDown } from "../../lib/roundOf";

import up_arw from "../../assets/images/up_arw.png";
import down_arw from "../../assets/images/down_arw.png";

const StakingCal = () => {
  //state
  const [selectedOption, setSelectedOption] = useState({});
  const [stakeData, setStakeData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [USDValue, setUSD] = useState(0);
  const [interest, setInterest] = useState(0);
  const [activeStakingMethod, setActiveStakingMethod] = useState("flexible");
  const [activeDepositTerm, setActiveDepositTerm] = useState("90");
  const [fixedAPY, setfixedAPY] = useState();
  const [coin, setCoin] = useState("");
  const [list, setList] = useState([]);
  const [interUSD, setInterUSD] = useState(0);
  const [ApyData, setApyData] = useState([]);

  //redux-state
  const priceConversion = useSelector((state) => state.priceConversion);

  //function
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      if (!isEmpty(value)) {
        let PriceCnv = priceConversion.find(
          (el) =>
            el.baseSymbol == selectedOption.value && el.convertSymbol == "USD"
        );
        let convert = !isEmpty(PriceCnv?.convertPrice)
          ? parseFloat(value * PriceCnv.convertPrice)
          : parseFloat(value);
        setUSD(toFixedDown(convert, 4));
      } else if (isEmpty(value)) {
        setUSD(0);
      }
      setAmount(value);
    }
  };

  const handleSelect = (eventKey) => {
    const newSelectedOption = list.find((option) => option.value === eventKey);
    setCoin(eventKey);
    setSelectedOption(newSelectedOption);
    setAmount(0);
    setUSD(0);
  };

  function interestCal(Method, amount, currency, fixedAPY, activeDepositTerm) {
    let Data =
      stakeData &&
      stakeData.length > 0 &&
      stakeData.find((el) => el.coin === currency && el.type.includes(Method));
    if (Method === "flexible") {
      let Value = interestByDays(amount, Data?.flexibleAPY, 365);
      setInterest(toFixedDown(Value, Data?.decimal));
      let PriceCnv = priceConversion.find(
        (el) => el.baseSymbol == currency && el.convertSymbol == "USD"
      );
      let priceData = !isEmpty(PriceCnv?.convertPrice)
        ? parseFloat(Value * PriceCnv.convertPrice)
        : parseFloat(Value);
      setInterUSD(toFixedDown(priceData, 4));
    } else if (Method === "fixed") {
      let Value = activeDepositTerm * interestByDays(amount, fixedAPY, 365);
      setInterest(toFixedDown(Value, Data?.decimal));
      let PriceCnv = priceConversion.find(
        (el) => el.baseSymbol == currency && el.convertSymbol == "USD"
      );
      let priceData = !isEmpty(PriceCnv?.convertPrice)
        ? parseFloat(Value * PriceCnv.convertPrice)
        : parseFloat(Value);
      setInterUSD(toFixedDown(priceData, 4));
    }
  }

  const handleStakingMethodClick = (button) => {
    setActiveStakingMethod(button);
    // setSelectedOption({})
    // setUSD(0)
  };

  const handleDepositTermClick = (buttonNumber) => {
    setfixedAPY(buttonNumber.APY);
    setActiveDepositTerm(buttonNumber.value);
  };

  const getStakeData = async () => {
    let reqData = {
      type: activeStakingMethod,
    };
    let arr = [];
    const { status, result } = await getStaking(reqData);
    if (status === "success") {
      if (result && result.length > 0) {
        if (isEmpty(selectedOption)) {
          setCoin(result[0]?.coin);
          setSelectedOption({
            label: result[0]?.image,
            value: result[0]?.coin,
          });
        } else {
          setStakeData(result);
          result &&
            result.length > 0 &&
            result.map((item, i) => {
              arr.push({
                label: item.image,
                value: item.coin,
              });
              setList(arr);
            });
        }
      }
    }
  };

  const APYfunction = (currency, type) => {
    let findData =
      stakeData &&
      stakeData.find((el) => el.coin === currency && el.type.includes(type));
    let arrayData = [];
    findData &&
      findData.periodList &&
      findData.periodList.length > 0 &&
      findData.periodList.map((el, key) => {
        arrayData.push({
          value: el.days,
          APY: el.APY,
          label: parseInt(el.days) / 30 + " month",
        });
        setApyData(arrayData);
      });
    setfixedAPY(findData?.periodList[0].APY);
  };

  useEffect(() => {
    getStakeData();
    interestCal(activeStakingMethod, amount, coin, fixedAPY, activeDepositTerm);
  }, [activeStakingMethod, amount, coin, activeDepositTerm, fixedAPY]);

  useEffect(() => {
    APYfunction(coin, activeStakingMethod);
  }, [activeStakingMethod, coin]);

  return (
    <>
      <section className="section calculatorbg">
        <div className="calculator">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="row">
              <div className="col-lg-12  ">
                <h2 className="main_title text-center">
                  Unleash the Power of Your Staking Rewards
                </h2>
              </div>
            </div>
            <div className="calculator_sub">
              <div class="row">
                <div className="col-lg-6">
                  <div className="calculator_sub_bg">
                    <img
                      src={require("../../assets/images/b-img1.png")}
                      alt="Image"
                      className="img-fluid "
                    />
                  </div>
                </div>
                <div class="col-lg-6 mx-auto">
                  <div
                    className="staking_calc"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div class="row g-3">
                      <div class="col-lg-6">
                        <label class="form-label">Asset</label>
                        <Dropdown
                          onSelect={handleSelect}
                          className="image_dropdown_wrapper"
                        >
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="image_dropdown"
                          >
                            <Image
                              src={selectedOption.label}
                              alt={selectedOption.value}
                              width={38}
                              height={38}
                            />
                            {selectedOption.value}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {list.map((option, i) => {
                              return (
                                <Dropdown.Item key={i} eventKey={option.value}>
                                  <Image
                                    src={option.label}
                                    alt={option.value}
                                    width={38}
                                    height={38}
                                  />{" "}
                                  {option.value}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div class="col-lg-6">
                        <label
                          for="amount"
                          class="form-label"
                          placeholder="0.00"
                        >
                          Amount
                        </label>
                        <div className="position-relative">
                          <input
                            type="number"
                            class="form-control"
                            onChange={handleChange}
                            value={amount}
                            name="amount"
                          />
                          <div className="arrows">
                            <img
                              src={up_arw}
                              alt="img"
                              className="img-fluid"
                              onClick={() => setAmount(amount + 1)}
                            />
                            <img
                              src={down_arw}
                              alt="img"
                              className="img-fluid"
                              onClick={() => setAmount(amount - 1)}
                            />
                          </div>
                        </div>
                        <div class="form-text ms-3">≈ ₮ {USDValue}</div>
                      </div>
                      <div className="whitebox-1">
                        <div class="col-lg-12 text-center">
                          <label class="form-label">Staking Method</label>
                          <div className="staking_method_btngrp">
                            <div className=" staking_method_btngrp1">
                              <button
                                className={
                                  activeStakingMethod === "flexible"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  handleStakingMethodClick("flexible")
                                }
                              >
                                Flexible
                              </button>
                              <button
                                className={
                                  activeStakingMethod === "fixed"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  handleStakingMethodClick("fixed")
                                }
                              >
                                Fixed
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 text-center">
                          <div
                            className={
                              activeStakingMethod === "fixed"
                                ? "active"
                                : "inactive"
                            }
                          >
                            <label class="form-label">
                              <svg
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-2"
                              >
                                <path
                                  d="M8.25 5.75H7.5C7.08579 5.75 6.75 5.41421 6.75 5C6.75 4.58579 7.08579 4.25 7.5 4.25H8.25M8.25 11.75C7.42157 11.75 6.75 11.0784 6.75 10.25V8.75C6.75 7.92157 7.42157 7.25 8.25 7.25M7.5 0.5C6.51509 0.5 5.53982 0.693993 4.62987 1.0709C3.71993 1.44781 2.89314 2.00026 2.1967 2.6967C0.790176 4.10322 0 6.01088 0 8C0 9.98912 0.790176 11.8968 2.1967 13.3033C2.89314 13.9997 3.71993 14.5522 4.62987 14.9291C5.53982 15.306 6.51509 15.5 7.5 15.5C9.48912 15.5 11.3968 14.7098 12.8033 13.3033C14.2098 11.8968 15 9.98912 15 8C15 7.01509 14.806 6.03982 14.4291 5.12987C14.0522 4.21993 13.4997 3.39314 12.8033 2.6967C12.1069 2.00026 11.2801 1.44781 10.3701 1.0709C9.46018 0.693993 8.48491 0.5 7.5 0.5Z"
                                  fill="#E8975B"
                                />
                              </svg>
                              Deposit Term
                            </label>
                            <div className="staking_method_btngrp">
                              {ApyData.map((button, i) => (
                                <button
                                  key={i}
                                  onClick={() => handleDepositTermClick(button)}
                                  className={
                                    activeDepositTerm === button.value
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {button.label}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                        {selectedOption?.value && (
                          <div class="col-md-12 mb-0">
                            <div className="staking_result_div">
                              {activeStakingMethod === "flexible" ? (
                                <p> In 12 months you will have total of </p>
                              ) : (
                                <p>
                                  In{" "}
                                  {parseInt(activeDepositTerm) / 30 +
                                    " months "}
                                  you will have a total of
                                </p>
                              )}
                              <div>
                                <h3>
                                  {interest ? interest : 0}{" "}
                                  {selectedOption?.value}
                                </h3>
                                <span>≈ ₮ {interUSD ? interUSD : 0}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StakingCal;
