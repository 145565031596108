import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";

//import lib
import isEmpty from "../../lib/isEmpty";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";
import { toastAlert } from "../../lib/toastAlert";
import { interestByDays } from "../../lib/calculation";

//import validation
import { StakingValidation } from "./Validation";

//import api
import { stakeLockedOrderPlace, stakeOrderPlace } from "../../api/staking";
import { getAssetData } from "../../api/walletAction";

const initialFormValue = {
  price: "",
};

const StakingModal = (props) => {
  // props
  const { isShow, record, onHide, type } = props;

  // redux
  const priceConversion = useSelector((state) => state.priceConversion);

  //state
  const [formValue, setFormValue] = useState(initialFormValue);
  const { price } = formValue;
  const [intrest_per, setintrest_per] = useState();
  const [intrest, setInterest] = useState();
  const [interUSD, setInterUSD] = useState(0);
  // const [indexval, setindexval] = useState();
  const [durationdays, setdurationdays] = useState(0);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [USDValue, setUSD] = useState(0);

  const dispatch = useDispatch();

  //function
  const handleClick = () => {
    let formData = { ...formValue, ...{ ["price"]: record?.stakeBal } };
    setFormValue(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "price") {
      if (/[^0-9.]/.test(value)) return;
    }
    let formData = { ...formValue, ...{ [name]: toFixedDown(value, 8) } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
    if (value) {
      setLoader(false);
    }
  };

  const durationloop = (durations, mainindex) => {
    let arr = [];
    // console.log(durations, 'durationsdurationsdurations')
    if (!isEmpty(durations) && durations.length > 0) {
      durations.map((duration, index, array) => {
        // console.log(duration, index, '000000000000000000', array)
        let months = parseInt(duration.days) / 30;
        if (index == array.length - 1) {
          arr.push(
            <button
              type="button"
              class="daysLocked"
              id={"act_" + mainindex + index}
              onClick={() => {
                onClick_Days(duration, mainindex, index);
              }}
            >
              {" "}
              {months} Month
            </button>
          );
        } else {
          arr.push(
            <button
              type="button"
              class={`daysLocked ${index == 0 ? "active" : ""}`}
              id={"act_" + mainindex + index}
              onClick={() => {
                onClick_Days(duration, mainindex, index);
              }}
            >
              {" "}
              {months} Month
            </button>
          );
        }
      });

      return arr;
    }
  };

  const onClick_Days = (duration, index, inc) => {
    $(".daysLocked").removeClass("active");
    $("#act_" + index + inc).addClass("active");
    // console.log(duration, "durationdurationdurationduration");
    setintrest_per(duration.APY);
    // setintrest(toFixed((duration.days * interestByDays(1000, duration.APY, 365)), 4))
    // setindexval(index);
    setdurationdays(duration.days);
  };

  const handleSubmit = async () => {
    try {
      if (type === "flexible") {
        setLoader(true);
        let reqData = {
          price,
          stakeId: record?._id,
          type: type,
        };
        let validationError = StakingValidation(reqData);
        if (!isEmpty(validationError)) {
          setErrors(validationError);
          setLoader(false);
          return;
        }
        const { status, loading, message } = await stakeOrderPlace(reqData);
        if (status === true) {
          setTimeout(() => {
            setLoader(false);
          }, 500);
          toastAlert("success", message, "login");
          hanldeClose();
        } else {
          setLoader(false);
          toastAlert("error", message, "login");
        }
      } else if (type === "fixed") {
        setLoader(true);
        let reqData = {
          price,
          stakeId: record?._id,
          type: type,
          duration_days: !isEmpty(durationdays)
            ? durationdays
            : record && record.periodList[0] && record.periodList[0].days,
        };
        let validationError = StakingValidation(reqData);
        if (!isEmpty(validationError)) {
          setErrors(validationError);
          setLoader(false);
          return;
        }
        const { status, loading, message } = await stakeLockedOrderPlace(
          reqData
        );
        if (status === true) {
          setTimeout(() => {
            setLoader(false);
          }, 500);
          toastAlert("success", message, "login");
          hanldeClose();
        } else {
          setLoader(false);
          toastAlert("error", message, "login");
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const hanldeClose = () => {
    onHide();
    setFormValue(initialFormValue);
    setInterUSD(0);
    setUSD(0);
    setErrors({});
  };

  function interestCal(
    Method,
    amount,
    currency,
    fixedAPY,
    activeDepositTerm,
    flexibleAPY
  ) {
    if (Method === "flexible") {
      let Value = interestByDays(amount, flexibleAPY, 365);
      setInterest(toFixedDown(Value, record?.decimal));
      let PriceCnv = priceConversion.find(
        (el) => el.baseSymbol == currency && el.convertSymbol == "USD"
      );
      setInterUSD(
        !isEmpty(PriceCnv?.convertPrice)
          ? parseFloat(Value) * parseFloat(PriceCnv.convertPrice)
          : parseFloat(Value)
      );
    } else if (Method === "fixed") {
      let Value =
        parseInt(activeDepositTerm) * interestByDays(amount, fixedAPY, 365);
      setInterest(toFixedDown(Value, record?.decimal));
      let PriceCnv = priceConversion.find(
        (el) => el.baseSymbol == currency && el.convertSymbol == "USD"
      );
      let priceData = !isEmpty(PriceCnv?.convertPrice)
        ? parseFloat(Value) * parseFloat(PriceCnv.convertPrice)
        : parseFloat(Value);

      setInterUSD(toFixedDown(priceData, 4));
    }

    if (!isEmpty(price)) {
      let PriceCnv = priceConversion.find(
        (el) => el.baseSymbol == record?.coin && el.convertSymbol == "USD"
      );
      let ValueData = !isEmpty(PriceCnv?.convertPrice)
        ? parseFloat(price) * parseFloat(PriceCnv.convertPrice)
        : parseFloat(price);
      setUSD(toFixedDown(ValueData, 4));
    } else if (isEmpty(price)) {
      setUSD(0);
    }
  }

  useEffect(() => {
    if (record) {
      if (type == "fixed") {
        setdurationdays(
          record.periodList && record.periodList[0] && record.periodList[0].days
        );
        setintrest_per(
          record.periodList && record.periodList[0] && record.periodList[0].APY
        );
      }
    }
    getAssetData(dispatch);
  }, [isShow, record]);

  useEffect(() => {
    interestCal(
      type,
      price,
      record?.coin,
      intrest_per,
      durationdays,
      record?.flexibleAPY
    );
  }, [
    type,
    price,
    record?.coin,
    intrest_per,
    durationdays,
    record?.flexibleAPY,
    priceConversion,
  ]);

  return (
    <>
      <Modal
        show={isShow}
        onHide={hanldeClose}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton onClick={hanldeClose}>
          {" "}
          <Modal.Title>
            <h4 className="modal-title mt-0">Staking</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="assets_form">
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <label for="withdrawamount" className="form-label">
                  Amount
                </label>
                <label className="max_label" onClick={handleClick}>
                  Max
                </label>
              </div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control primary_asset_inp"
                  placeholder="0.000000"
                  name="price"
                  value={price}
                  onChange={handleChange}
                />

                <span class="primary_asset_inp">{record?.coin}</span>
              </div>
              <div class="form-text ms-3">
                ≈ ₮ {" "}
                <CurrencyFormat
                  displayType={"text"}
                  thousandSeparator={true}
                  value={USDValue}
                />
              </div>
              <span className="text-danger">{errors?.price}</span>
            </div>
            <div className="deposit_asset_details">
              <div>
                <label>Interest</label>
                <label>
                  {intrest ? (
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={intrest}
                    />
                  ) : (
                    0
                  )}{" "}
                  {record?.coin}{" "}
                  <span>
                    ≈ ₮{" "}
                    {interUSD ? (
                      <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator={true}
                        value={toFixedDown(interUSD, 4)}
                      />
                    ) : (
                      0
                    )}
                  </span>
                </label>
              </div>
              <div>
                <label>Available Balance</label>
                <label>
                  <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={truncateDecimals(record?.stakeBal, 4)}
                  />{" "}
                  {record?.coin}{" "}
                </label>
              </div>
              {/* <div>
                                <label>Minimum Deposit</label>
                                <label>{record.minimumDeposit} {record?.coin}</label>
                            </div> */}
              <div>
                <label>Minimum Stake</label>
                <label>
                  <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={record.minimumAmount}
                  />{" "}
                  {record?.coin}
                </label>
              </div>
              <div>
                <label>Maximum Stake</label>
                <label>
                  <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={record.maximumAmount}
                  />{" "}
                  {record?.coin}
                </label>
              </div>
              <div>
                <label>Withdrawal Term</label>
                <label>
                  {type === "flexible"
                    ? "Flexible"
                    : "Locked for " + durationdays / 30 + " month"}{" "}
                </label>
              </div>
              {type != "fixed" && (
                <div>
                  <label>Redemption Period</label>
                  <label>{record.redemptionPeriod} Days</label>
                </div>
              )}
              <div>
                <label>Duration (Days)</label>
                <label>{type === "fixed" ? durationdays : 365} </label>
              </div>
              <div>
                <label> APY</label>
                <label className="txt_green">
                  {type === "fixed" ? intrest_per : record?.flexibleAPY}%
                </label>
              </div>
              {type === "fixed" && (
                <div className="modal_staking_period">
                  <label>Staking Period</label>
                  <div class="staking_method_btngrp mb-3 mt-1">
                    {durationloop(record?.periodList)}
                  </div>
                </div>
              )}
            </div>
            <div className="asset_modal_btn_grp mt-4 mb-4">
              <button
                type="button"
                className="w-100 primary_btn primary_btn1"
                onClick={hanldeClose}
              >
                Cancel
              </button>
              {loader ? (
                <button type="button" className="primary_btn " disabled={true}>
                  Loading...
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="primary_btn "
                >
                  Confirm
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StakingModal;
